import { FormEventHandler, useCallback, useState } from "react"
import Link from "next/link"
import tw from "twin.macro"

import API from "lib/api"
import useAuth from "lib/useAuth"
import useMuwayiTheme from "lib/useMuwayiTheme"

import { Input, CheckboxInput } from "components/Forms"
import Container from "components/Container"
import PrimaryButton from "components/Buttons/Primary"
import Column from "components/Column"
import Grid from "components/Grid"
import Image from "components/Image"
import { BodyCopy, BodyDetail, H2 } from "styles/Text"
import withSSRUser from "lib/withSSRUser"
import { useRouter } from "next/router"

export default function LoginPage({ redirected }) {
  const { login } = useAuth()
  const { countryImage } = useMuwayiTheme()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [shouldPersist, setShouldPersist] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault()
      setIsLoading(true)
      setError(null)
      try {
        const data = new FormData(e.target as HTMLFormElement)
        await login(data, shouldPersist)
        router.push(redirected || "/account")
      } catch (err) {
        setError(
          "Your password or email is incorrect. Try again or hit forgot password if you can't remember it."
        )
        console.error(err)
      }
      setIsLoading(false)
    },
    [login, shouldPersist, redirected, router]
  )

  return (
    <div tw="bg-white relative z-10 min-h-screen overflow-hidden">
      <img
        src="/assets/gumleaf.svg"
        tw="absolute -left-5 top-40 -z-10 opacity-20"
        alt=""
      />
      <Container>
        <Grid halign="justify" valign="center">
          <Column size={{ md: 6 / 12, lg: 5 / 12 }}>
            <div tw="md:pt-30 pb-10 xl:pr-12">
              <H2 tw="mb-5">
                {redirected ? (
                  <>
                    This page is only for members of this{" "}
                    <span tw="text-primary">Muwayi</span>
                  </>
                ) : (
                  "Sign in"
                )}
              </H2>
              <BodyCopy tw="mb-7 xl:mr-8" css={error && tw`text-earth`}>
                {error ||
                  (redirected &&
                    "Sign in with your account to view this page") ||
                  "Are you part of this Muwayi? Sign into your account to view all of our pages."}
              </BodyCopy>
              <BodyDetail tw="mb-7 xl:mr-8">
                Don&apos;t have an account?{" "}
                <Link href="/register">
                  <a className="underline-primary">Request access</a>
                </Link>
              </BodyDetail>

              <form onSubmit={onSubmit}>
                <div tw="mb-5">
                  <Input
                    dark
                    label="Email"
                    placeholder="Your email address"
                    name="identifier"
                    type="email"
                    required
                  />
                </div>
                <div tw="mb-5">
                  <Input
                    dark
                    label="Password"
                    placeholder="Enter your password"
                    name="password"
                    type="password"
                    required
                  />
                </div>
                <div tw="flex justify-between mt-5">
                  <CheckboxInput
                    name="persistent"
                    value="true"
                    label="Remember me"
                    onChange={() => setShouldPersist((b) => !b)}
                    checked={shouldPersist}
                  />
                  <Link href="/password/forgot" passHref>
                    <BodyDetail as="a" className="underline-primary">
                      Forgot password?
                    </BodyDetail>
                  </Link>
                </div>
                <div tw="lg:w-2/5 flex flex-col mt-5">
                  <PrimaryButton
                    disabled={isLoading}
                    as="button"
                    type="submit"
                    tw="mt-5"
                  >
                    {isLoading ? "Logging in..." : "Log in"}
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </Column>
          <Column size={{ md: 6 / 12 }} tw="order-first md:order-none">
            <div tw="md:min-h-screen relative flex items-center md:pt-30 md:pb-5 overflow-hidden rounded-full md:rounded-none -mx-20 -mt-40 mb-5 md:mb-0 md:mt-0 md:mx-0 md:overflow-visible">
              <div tw="shadow-2xl flex bg-light-grey">
                <Image
                  aria-hidden="true"
                  alt={countryImage?.alternativeText || "Bunuba Country"}
                  src={countryImage?.url || "/assets/signup-bg.jpg"}
                  width="590"
                  height="630"
                  objectFit="cover"
                />
              </div>
            </div>
          </Column>
        </Grid>
      </Container>
    </div>
  )
}

export const getServerSideProps = withSSRUser(async ({ params, query }) => {
  if (params?.user) {
    return {
      props: {},
      redirect: {
        permanent: false,
        destination: "/account",
      },
    }
  }
  const [{ data: theme }, { data: nav }] = await Promise.all([
    API.cachedGet("/theme", 60000),
    API.cachedGet("/navigation", 60000),
  ])
  return {
    props: {
      loginChrome: true,
      theme,
      nav,
      user: params?.user || null,
      redirected: query?.redirected_from || null,
    },
  }
})
